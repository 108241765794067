$(document).ready(function() {
  //initialize swiper when document ready
  sliderInit();
  fixmeTopHome = $('.about-section').offset().top;
  if (fixmeTopHome > 0) {
      fixmeTopHome = 0;
  }
});

function sliderInit() {
  var swiper = new Swiper('.swiper-container', {
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      effect: "fade",
      autoplay: {
          delay: 5000,
      },
      pagination: {
          el: '.swiper-pagination',
          clickable: true,
      },
      navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
      },
  });
}

$(document).scroll(function() {

$(".overlay").css("height", "100vh");
  var currentScrollHome = $(window).scrollTop();
  if (currentScrollHome > fixmeTopHome) {
      $('.about-section').addClass('fixed');
      // console.log('s');
  } else {
      $('.about-section').removeClass('fixed');
      // console.log('m');
  }

});
